import React, { createContext, useContext, useReducer } from 'react'
import { BROWSER_TIMEZONE } from '../constants/timezone'

interface State {
  events: any[]
}

interface EventListAction {
  type: 'registrations' | 'opportunities'
  payload: any[]
}

export type EventListDispatch = (action: EventListAction) => void

interface Props {
  children: React.ReactNode
}

const EventListStateContext = createContext<State | undefined>(undefined)
const EventListDispatchContext = createContext<EventListDispatch | undefined>(undefined)

const eventListReducer = (state: State, action: EventListAction) => {
  switch (action.type) {
    case 'registrations':
    case 'opportunities':
      const events = mapResponseToEvents(action)
      return { events }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const mapResponseToEvents = (action: EventListAction) => {
  const { payload, type } = action
  const isRegistrationEvent = type === 'registrations'

  return payload.map((event: any) => {
    const mainDetails = isRegistrationEvent
      ? event.opportunity
      : event

    const {
      name,
      description,
      instructions,
      timeshifts,
      address,
      city,
      state,
      zip,
      geofencing,
      startsAt,
      endsAt,
      organization,
      opportunityId,
      status,
    } = mainDetails

    return {
      name,
      organization: {
        website: organization.website,
        name: organization.name,
        id: organization.id,
      },
      description,
      instructions,
      timeshifts,
      address,
      city,
      state,
      zip,
      mapPosition: {
        lat: geofencing.location.coordinates[1],
        lng: geofencing.location.coordinates[0],
      },
      locationDetails: event.locationDetails,
      locationIsAddress: event.locationIsAddress,
      startsAt,
      endsAt,
      timeZone: organization.timeZone ? organization.timeZone : BROWSER_TIMEZONE,
      details: isRegistrationEvent ? event.details : [],
      id: event.id,
      sourceType: isRegistrationEvent ? 'registration' : 'opportunity',
      missingRequiredMemberships: isRegistrationEvent ? undefined : event.missingRequiredMemberships,
      opportunityId,
      status,
    }
  })
}

const EventListProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(eventListReducer, { events: [] })

  return (
    <EventListStateContext.Provider value={state}>
      <EventListDispatchContext.Provider value={dispatch}>{children}</EventListDispatchContext.Provider>
    </EventListStateContext.Provider>
  )
}

const useEventListState = () => {
  const context = useContext(EventListStateContext)

  if (context === undefined) {
    throw new Error('useEventListState must be used within an EventListProvider')
  }

  return context
}

const useEventListDispatch = () => {
  const context = useContext(EventListDispatchContext)

  if (context === undefined) {
    throw new Error('useEventListDispatch must be used within an EventListProvider')
  }

  return context
}

const withEventListDispatch = (Component: React.ComponentType<any>) => {
  const EventListHooksContainer = (props: any) => {
    const dispatch = useEventListDispatch()

    return <Component eventListDispatch={dispatch} {...props} />
  }

  return EventListHooksContainer
}

export { EventListProvider, useEventListState, useEventListDispatch, withEventListDispatch }
