import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import cn from 'classnames'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react'
import { TwitterShareButton, FacebookShareButton } from 'react-share'
import queryString from 'query-string'
import { isEmpty } from 'lodash'
import { ROUTE_NAMES } from '../../constants/route-names'
import { Loader } from '../../kit/components/loader'
import { TimeAndPlace } from '../../kit/components/time-and-place-block'
import IconComponent from '../../kit/components/icon'
import { WhoIsNeeded } from '../../kit/components/who-is-needed'
import CancelRegistrationPopup from '../../kit/modules/events/cancel-registration-popup'
import ENDPOINT_ROUTES from '../../constants/APIs'
import FeatureEnabled from '../../kit/modules/features/FeatureEnabled'
import { useEventListState, useEventListDispatch } from '../../contexts/EventListContext'
import { withFeatureEnabled } from '../../contexts/FeatureContext'
import { encodeOccurrence, decodeOccurrenceId } from '../../helpers/encodeOccurrence'
import './styles.scss'

const { REACT_APP_GOOGLE_MAPS_KEY } = process.env

interface NotFoundProps {
  goBack: () => void
  children: any
}

interface Props {
  match?: any
  history?: any
  google?: any
  isMyEvent?: boolean
}

export enum OpportunityStatus {
  Draft = 'Draft',
  Concluded = 'Concluded',
  Live = 'Live',
  Upcoming = 'Upcoming',
}

interface EventDetails {
  name: string
  organization: {
    website: string
    name: string
    id: number | null
  }
  description: string
  instructions: string
  timeshifts: any[]
  address: string
  missingRequiredMemberships?: boolean
  city: string
  state: string
  zip: string
  mapPosition: {
    lat: number
    lng: number
  }
  locationDetails: string
  locationIsAddress: boolean
  startsAt: string
  endsAt: string
  timeZone: any
  details: any[]
  id: number | null
  sourceType: 'opportunity' | 'registration' | ''
  opportunityId: number | string
  status?: OpportunityStatus
}

const initialDetails: EventDetails = {
  name: '',
  organization: {
    website: '',
    name: '',
    id: null,
  },
  description: '',
  instructions: '',
  timeshifts: [],
  address: '',
  city: '',
  state: '',
  zip: '',
  mapPosition: {
    lng: 0,
    lat: 0,
  },
  locationDetails: '',
  locationIsAddress: true,
  startsAt: '',
  endsAt: '',
  timeZone: null,
  details: [],
  id: null,
  sourceType: '',
  opportunityId: '',
}

const VolunteersEventDetailsScene = (props: Props | any) => {
  const [apiError, setApiError] = useState('')
  const [apiErrorMessage, setApiErrorMessage] = useState('')
  const [details, setDetails] = useState(initialDetails)
  const [allRoles, setAllRoles] = useState(null)
  const [cancelPopup, setCancelPopup] = useState(false)
  const [availableSlots, setAvailableSlots] = useState<number | null>(null)
  const [roleById, setRoleById] = useState([])
  const [eventId, setEventId] = useState(props.match.params.eventId)
  const {
    currentUser,
    getJSON,
    google,
    history,
    isMyEvent,
    match,
    setOpenLogin,
    data: { redirectToRegisterAfterLogin },
  } = props
  const { token } = queryString.parse(props.location.search)
  const { organizationId } = props.match.params
  const pathName = window.location.href
  const isUserSignedIn = useRef(!isEmpty(currentUser) && !currentUser.error)
  const { events } = useEventListState()
  const eventListDispatch = useEventListDispatch()

  const dispatchFromNewCall = async (type: 'registrations' | 'opportunities') => {
    let response

    if (type === 'opportunities') {
      const endpoint = ENDPOINT_ROUTES.Events.getOccurrence(
        match.params.eventId,
        token
          ? {
              token: String(token),
              includeMissingRequiredMemberships: true,
            }
          : {
              includeMissingRequiredMemberships: true,
            },
      )

      response = await getJSON(endpoint)
    } else {
      const endpoint = ENDPOINT_ROUTES.Events.getMyEventByIdOccurrenceRefactor(eventId)

      response = await getJSON(endpoint)
    }

    if (response.error) {
      setApiError(response.error)
      setApiErrorMessage(response.message)
      return
    }

    eventListDispatch({ type, payload: [response] })
  }

  const attemptRegistrationUpgradeFromOpportunity = async (storedOpportunity: EventDetails) => {
    const endpoint = ENDPOINT_ROUTES.Events.getMyEventsOccurrenceRefactor({
      encodedInstance: encodeOccurrence(
        storedOpportunity.opportunityId,
        storedOpportunity.startsAt,
        storedOpportunity.timeZone,
      ),
    })
    const registrationResponse = await getJSON(endpoint)

    const isEmpty = registrationResponse.length === 0

    if (isEmpty) {
      if (storedOpportunity) {
        setDetailsAndRoles(storedOpportunity)
        return
      }

      dispatchFromNewCall('opportunities')
      return
    }

    setEventId(registrationResponse[0].id)
    eventListDispatch({ type: 'registrations', payload: registrationResponse })
  }

  const attemptRegistrationUpgradeFromOpportunityParams = async (encodedInstance: string) => {
    const endpoint = ENDPOINT_ROUTES.Events.getMyEventsOccurrenceRefactor({ encodedInstance })

    const registrationResponse = await getJSON(endpoint)
    const isEmpty = registrationResponse.length === 0

    if (!isEmpty) {
      setEventId(registrationResponse[0].id)
      eventListDispatch({ type: 'registrations', payload: registrationResponse })
    } else {
      dispatchFromNewCall('opportunities')
    }
  }

  const setDetailsAndRoles = async (nextDetailsOnRender: EventDetails) => {
    setDetails(nextDetailsOnRender)

    if (nextDetailsOnRender.organization.id) {
      const endpoint = ENDPOINT_ROUTES.Events.getRolesOccurrenceRefactor(nextDetailsOnRender.organization.id)
      const responseRoles = await getJSON(endpoint)
      setAllRoles(responseRoles)
    }

    const timeShifts = nextDetailsOnRender.timeshifts && nextDetailsOnRender.timeshifts.flat()

    if (nextDetailsOnRender.sourceType === 'registration') {
      setAvailableSlots(0)
    }

    if (nextDetailsOnRender.sourceType === 'opportunity' && timeShifts.length) {
      const roles = timeShifts.map((item: any) => {
        return item.roles
      })

      const rolesArr: any = roles.flat()
      setRoleById(rolesArr)

      const availableSlot = rolesArr.map((item: any) => {
        return item.available
      })

      const totalAvailable = availableSlot.reduce((a: number, b: number) => a + b, 0)
      setAvailableSlots(totalAvailable)
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 })

    let contextMatch = null
    let hasStoredOpportunity = false
    let hasStoredRegistration = false
    const decodedParams = !isMyEvent
      ? decodeOccurrenceId(match.params.eventId)
      : {
          date: '',
          opportunityId: '',
        }

    if (events.length > 0) {
      const isListOfOpportunities = events[0].sourceType === 'opportunity'

      if (!isMyEvent) {
        contextMatch = events.find(
          ({ startsAt, opportunityId }) =>
            moment(startsAt).format('YYYY-MM-DD') === decodedParams.date &&
            opportunityId === decodedParams.opportunityId,
        )
      } else {
        contextMatch = isListOfOpportunities
          ? null
          : events.find(({ id, sourceType }) => id === parseInt(eventId) && sourceType === 'registration')
      }
    }

    hasStoredRegistration = contextMatch && contextMatch.sourceType === 'registration'
    hasStoredOpportunity = contextMatch && contextMatch.sourceType === 'opportunity'

    if (hasStoredRegistration || (hasStoredOpportunity && !isUserSignedIn.current)) {
      setDetailsAndRoles(contextMatch)
      return
    }

    if (hasStoredOpportunity && isUserSignedIn.current) {
      attemptRegistrationUpgradeFromOpportunity(contextMatch)
      return
    }

    if (isMyEvent) {
      dispatchFromNewCall('registrations')
      return
    }

    if (!isMyEvent && isUserSignedIn.current) {
      // in this case, the eventId should be an encoded instance in the URL
      attemptRegistrationUpgradeFromOpportunityParams(eventId)
      return
    }

    dispatchFromNewCall('opportunities')

    return function () {
      setOpenLogin(false)
    }
  }, [events]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isUserSignedIn.current && !isEmpty(currentUser) && !currentUser.error) {
      isUserSignedIn.current = true

      if (redirectToRegisterAfterLogin) {
        redirectToRegister(false)
      }

      if (details && details.sourceType === 'opportunity' && details.id) {
        attemptRegistrationUpgradeFromOpportunity(details)
      }
    }
  }, [currentUser]) // eslint-disable-line react-hooks/exhaustive-deps

  const goBack = () => {
    history.push(ROUTE_NAMES.allEvents(organizationId))
  }

  if (apiError) {
    return <NotFound goBack={() => goBack()}>{apiErrorMessage}</NotFound>
  }

  if (!details.id || !allRoles || availableSlots === null) {
    return <Loader />
  }

  const shareMessage = `I just signed up to volunteer and help ${details.organization.name} with their event ${details.name}. Join me!`

  const redirectToRegister = (isEditMode: boolean) => {
    if (!currentUser || !details.id) {
      return
    }

    const pathname = ROUTE_NAMES.eventRegistrationRefactor(
      organizationId,
      encodeOccurrence(details.opportunityId, details.startsAt, details.timeZone),
      token ? String(token) : undefined,
    )
    const eventRegistrationPathConfig = Object.assign({
      pathname,
      state: {
        eventId: encodeOccurrence(details.opportunityId, details.startsAt, details.timeZone),
        isEditMode,
      },
    })
    history.push(eventRegistrationPathConfig)
  }

  const address = {
    address: details.address,
    city: details.city,
    state: details.state,
    zip: details.zip,
    locationIsAddress: details.locationIsAddress,
    locationDetails: details.locationDetails,
  }

  const createMarkup = (html: string) => {
    if (html.includes('/script>')) {
      return { __html: '' }
    }
    return { __html: html }
  }

  return (
    <>
      <div className="event_details_wrapper">
        {!token && (
          <div className="event_details_back">
            <div className="event_details_back_block" onClick={goBack}>
              <img src={'/assets/images/back_arrow.svg'} alt="back" className="event_details_back_icon" />
              Back to Events
            </div>
          </div>
        )}
        <div className="event_details_block">
          <div className="event_details_block_left">
            <div className="event_details_block_left_title">{details.name}</div>
            <div className="event_details_block_left_subtitle">
              <a href={details.organization.website}>{details.organization.name}</a>
            </div>
            <TimeAndPlace
              dateStart={details.startsAt}
              dateEnd={details.endsAt}
              {...address}
              timeZone={details.timeZone}
            />
            <div className="event_details_block_left_description">
              {details.description && <div dangerouslySetInnerHTML={createMarkup(details.description)} />}
            </div>
            {details && details.instructions && (
              <div className="event_details_block_left_description">
                <div dangerouslySetInnerHTML={createMarkup(details.instructions)} />
              </div>
            )}
          </div>
          <div
            className={`event_details_block_right ${
              details.sourceType === 'opportunity' && 'event_details_block_right--mobile'
            }`}
          >
            <div className="event_details_block_right_map_wrapper">
              <div className="event_details_block_right_map">
                <Map google={google} zoom={14} initialCenter={details.mapPosition} disableDefaultUI>
                  <Marker
                    title={details.organization.name}
                    name={details.organization.name}
                    position={details.mapPosition}
                  />
                </Map>
              </div>
              <FeatureEnabled code="SocialSharing">
                <div className="event_details_block_right_share">
                  <div className="event_details_block_right_share_social">
                    <IconComponent name="facebook" height="24px" width="24px" />
                    <FacebookShareButton url={pathName} quote={shareMessage}>
                      Share on Facebook
                    </FacebookShareButton>
                  </div>
                  <div className="event_details_block_right_share_social">
                    <IconComponent name="twitter" height="24px" width="24px" />
                    <TwitterShareButton url={pathName} title={shareMessage}>
                      Share on Twitter
                    </TwitterShareButton>
                  </div>
                </div>
              </FeatureEnabled>
              {details.sourceType === 'opportunity' &&
                (availableSlots > 0 ? (
                  <div
                    className={cn('event_details_block_right_register', {
                      disabled: details.missingRequiredMemberships || details.status === OpportunityStatus.Concluded,
                    })}
                    onClick={() => {
                      if (details.missingRequiredMemberships) {
                        return
                      }

                      return !!currentUser.email ? redirectToRegister(false) : setOpenLogin(true, true)
                    }}
                  >
                    Register
                  </div>
                ) : (
                  <div className="event_details_block_right_register_empty">This event is full</div>
                ))}
            </div>
            <div className="event_details_block_right_info_wrapper">
              {details.sourceType === 'registration' ? (
                <div className="event_details_block_right_info">
                  <div className="event_details_block_right_info_title">You’re signed up!</div>
                  {details.details.map((item: any, index: number) => {
                    return (
                      <div className="event_details_block_right_info_role" key={index}>
                        <div className="event_details_block_right_info_role_icon" />
                        <div className="event_details_block_right_info_role_shift">
                          <div className="event_details_block_right_info_role_shift_time">
                            {`${item.timeshift.time_start}
                              ${item.timeshift.time_end}`}
                          </div>
                          <div className="event_details_block_right_info_role_shift_slot">
                            Your role is <b>{item.role.name}</b>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  {/* TODO: https://civicchamps.atlassian.net/browse/CCA-983 */}
                  {/*<div className="event_details_block_right_edit" onClick={() => redirectToRegister(true)}>*/}
                  {/*  Edit Registration*/}
                  {/*</div>*/}
                  <div className="event_details_block_right_cancel" onClick={() => setCancelPopup(true)}>
                    Cancel Registration
                  </div>
                </div>
              ) : (
                <div className="event_details_block_right_info event_details_block_right_info--mobile">
                  <div className="event_details_block_right_info_title">How You Can Help</div>
                  <div className="event_details_block_right_info_subtitle">
                    {details.status === OpportunityStatus.Concluded
                      ? 'This event has ended and is no longer accepting registrations.'
                      : availableSlots > 0
                      ? `${availableSlots} Volunteers Still needed!`
                      : 'No more Volunteers needed!'}
                  </div>
                  {details.status !== OpportunityStatus.Concluded && (
                    <div className="event_details_block_right_info_members">
                      {roleById.length > 0 &&
                        roleById.map((item: any, key: number) => {
                          return (
                            <WhoIsNeeded
                              key={key}
                              totalMembers={item.available}
                              timeShifts={details.timeshifts}
                              opportunity_timeshift_id={item.opportunity_timeshift_id}
                              roleName={item.name}
                              allRoles={allRoles}
                            />
                          )
                        })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {
        <CancelRegistrationPopup
          eventName={details.name}
          eventId={details.id}
          isOpen={cancelPopup}
          onClosePopup={(value: boolean) => setCancelPopup(value)}
          orgId={organizationId}
          {...props}
        />
      }
    </>
  )
}

const NotFound = ({ goBack, children }: NotFoundProps) => {
  return (
    <div className="event_details_wrapper">
      <div className="event_details_back">
        <div className="event_details_back_block" onClick={goBack}>
          <img src={'/assets/images/back_arrow.svg'} alt="back" className="event_details_back_icon" />
          Back to Events
        </div>
        <div className="event_details_not_found">{children}</div>
      </div>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: `${REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp`,
  libraries: ['geometry', 'drawing', 'places'],
})(withFeatureEnabled(VolunteersEventDetailsScene))
